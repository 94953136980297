
  
const Load = () => {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
         <img src="https://res.cloudinary.com/dtqdoinxc/image/upload/v1702746392/dreamdestiny/loader_hesese.gif" alt="my-gif" />
      </div>
    );
  };
  
  export default Load;
